.navigation-component {
    .font-large {
        font-size: 20px;
    }

    .font-large-mui {
        font-size: 26px
    }

    .font-small {
        font-size: 12px;
    }

    .button-hover-disable {
        &:hover {
            border-color: transparent !important;
        }
    }

    p,
    a {
        color: #1C1C1D;
    }

    .btn-outline-dark {
        border: 1px solid #404040;
        --bs-btn-border-color: #404040;
    }

    .profile-icons {
        color: #404040 !important;

        &:hover {
            color: #FFFFFF !important;
        }
    }

    .menu-icon {
        color: #404040;

        &:hover {
            color: #FFFFFF;
        }
    }

    .appbar-icon {
        color: #404040;
    }


    .dropdown-menu.show {
        min-width: 200px;
    }

    .nav-item {
        padding: 0px 10px;
    }

    .nav-item,
    .dropdown-item {
        &:hover {
            background-color: #DDDDDD;
        }
    }

    .form-modal {
        .form-input-box {
            background-color: transparent;
            border-radius: 12px;
            padding: 8px;
            padding-left: 28px;
            cursor: pointer;
            background-color: #FFFFFF;
    
            &.hover {
                background-color: #DDDDDD;
            }
    
            &.active {
                background-color: #FFFFFF;
            }
    
            label {
                font-weight: 700;
                font-size: 14px;
                cursor: pointer;
                margin: 0px;
            }
    
            input {
                border: none;
                margin: 0px;
                padding: 0px;
                background-color: transparent;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
    
                @media (max-width: 991px) {
                    //width: 105px;
                }
    
                &::placeholder {
                    color: #000000;
                    opacity: 0.5;
                }
    
                &:focus {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
    
                &:hover {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
    
                &:active {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
    
            }
    
            .clear-input {
                position: absolute;
                right: 10px;
                top: 14px;
                font-size: 16px;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }
}
.item-view-component {
    .main-image {
        aspect-ratio: 1 / 1;
        width: 100%;
        border-radius: 18px 0px 0px 18px;

        @media screen and (max-width: 768px) {
            border-radius: 0px 0px 18px 18px;
        }
    }

    .extra-image-01 {
        aspect-ratio: 1 / 1;
        width: 100%;
    }

    .extra-image-02 {
        aspect-ratio: 1 / 1;
        width: 100%;
        border-radius: 0px 18px 0px 0px;
    }

    .extra-image-03 {
        aspect-ratio: 1 / 1;
        width: 100%;
    }

    .extra-image-04 {
        aspect-ratio: 1 / 1;
        width: 100%;
        border-radius: 0px 0px 18px 0px;
    }



    .item-divider {
        margin: 30px 10px;
        border-bottom: 1px solid #e0e0e0;

    }

    .info-summary {
        .item-title {
            font-size: 26px;
            font-weight: 600;
            margin: 20px 0px 0px 0px;

            @media screen and (max-width: 768px) {
                margin-bottom: 6 0px;
            }

            .item-rating {
                margin: 0px 0px 0px 10px;
                font-size: 17px;
                font-weight: 500;
                height: 17px;

                .material-icons {
                    margin-top: 10px
                }
            }
        }

        .item-description {
            margin: 20px 0px 0px 0px;
            font-size: 22px;
            font-weight: 600;

            @media screen and (max-width: 768px) {
                margin-top: 6px;
            }
        }

        .item-animals {
            .item-animals-title {
                font-size: 22px;
                font-weight: 600;
                margin: 20px 0px;
            }

            .item-animals-list {
                margin: 20px 0px;
                gap: 4px;

                display: flex;
                flex-wrap: wrap;
                

                .item-animals-item {
                    background-color: #e0e0e0;
                    width: fit-content;
                    padding: 4px 10px;
                    border-radius: 15px;
                }
            }
        }

        .item-full-description {
            .item-full-description-title {
                font-size: 22px;
                font-weight: 600;
                margin: 20px 0px;
            }
        }

        .item-price {
            font-size: 16px;
            margin-bottom: 0rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .currency-container {
                font-size: 28px;
                margin-right: 6px;
            }

        }

        .item-subtitle {
            margin: 0px 0px;
            height: 24px;
            font-size: 16px;
        }

        .item-booking {
            margin: 20px 0px;
            div {
                
                background-color: #e0e0e0;
                border-radius: 15px;
                height: 200px;
            }
        }

        .item-checkin-checkout {
            margin: 20px 0px;
        }

        .item-features {
            .item-features-title {
                font-size: 22px;
                font-weight: 600;
                margin: 20px 0px;
            }

            .item-features-list {
                margin: 20px 0px;
                gap: 4px;

                .item-features-item {
                    background-color: #e0e0e0;
                    width: fit-content;
                    padding: 4px 10px;
                    border-radius: 15px;
                }
            }
        }

        .item-terms-and-conditions {
            .item-terms-and-conditions-title {
                font-size: 22px;
                font-weight: 600;
                margin: 20px 0px;
            }
        }

        .item-reviews {
            .item-reviews-title {
                font-size: 22px;
                font-weight: 600;
                margin: 20px 0px;
            }

            .item-reviews-list {
                .item-review {
                    border: 1px solid #e0e0e0;
                    border-radius: 18px;
                    padding: 20px;
                    margin-bottom: 10px;
                }
            }
        }

        .item-host {
            .item-host-title {
                font-size: 22px;
                font-weight: 600;
            }

            .item-host-name {
                font-size: 16px;
                margin: 5px 0px;
                font-weight: 500;
            }

            .item-host-description {
                font-size: 16px;
                margin: 0px 0px;
            }
        }

        
    }

    .sticky-card-contianer {
        position: relative;

        .sticky-card {
            width: 80%;
            position: sticky;
            top: 110px;
            right: 0px;
            margin: 30px 0px 10px 0px;
            margin-left: 20%;
            border: 1px solid #e0e0e0;
            border-radius: 18px;
            padding: 20px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 6px rgba(0, 0, 0, 0.03);

            .sticky-card-divider {
                margin: 20px 0px;
                border-bottom: 1px solid #e0e0e0;
            }

            .sticky-card-price {
                font-size: 16px;
                margin-bottom: 0rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                .currency-container {
                    font-size: 28px;
                    margin-right: 6px;
                }

            }

            .sticky-car-subtitle {
                margin: 0px 0px;
                height: 24px;
                font-size: 16px;
            }

            .sticky-card-booking {
                margin: 20px 0px;
                div {
                    background-color: #e0e0e0;
                    border-radius: 15px;
                    height: 200px;
                }
            }

            .sticky-card-checkin-checkout {
                margin: 20px 0px;
            }
        }

    }


    .map {
        .map-title {
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            margin: 20px 0px;
        }

        .map-item {
            aspect-ratio: 20 / 10;
            //height: 500px;
            background-color: #e0e0e0;
            border-radius: 15px;
        }
    }
}
.grid-view {
    position: relative;

    &.horizontal-scrollable>.row {
        white-space: nowrap;
        overflow-x: auto;
        display: block;
    }

    &.horizontal-scrollable>.row>.col-lg-3 {
        display: inline-block;
    }

    ::-webkit-scrollbar:horizontal {
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #556B2F;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
}
.top-search {
    width: fit-content;
    background-color: #FFFFFF;
    border-radius: 12px;
    position: relative;
    top: 36px;
    margin: 0 auto;

    .form-control:focus {
        border-color: transparent !important;
        box-shadow: none !important;
      }

    &.hover {
        background-color: #EBEBEB;
    }

    .form-input-container {
        position: relative;

        &::after {
            position: absolute;
            height: 44px;
            display: block;
            width: 1px;
            background: #DDDDDD;
            border-right: 1px white;
            top: 10px;
            right: 0px;
            content: '';
        }

        &.no-after {
            &::after {
                display: none;
            }
        }

        &:first-child {
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
        }

        &:last-child {
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;

            &::after {
                display: none;
            }
        }

        &.hover {
            background-color: #DDDDDD;
        }

        &.hover-left {
            background: linear-gradient(90deg, #DDDDDD 50%, transparent 50%);
        }

        &.hover-right {
            background: linear-gradient(90deg, transparent 50%, #DDDDDD 50%);
        }

        &.active {
            background: #FFFFFF;
        }

        &.active-left {
            background: linear-gradient(90deg, transparent 50%, #FFFFFF 50%);
        }

        &.active-right-hover-left {
            background: linear-gradient(90deg, #DDDDDD 50%, #FFFFFF 50%);
        }
    }

    .form-input-box {
        background-color: transparent;
        border-radius: 12px;
        padding: 8px;
        padding-left: 28px;
        cursor: pointer;

        &.hover {
            background-color: #DDDDDD;
        }

        &.active {
            background-color: #FFFFFF;
        }

        label {
            font-weight: 700;
            font-size: 14px;
            cursor: pointer;
            margin: 0px;
        }

        input {
            border: none;
            margin: 0px;
            padding: 0px;
            background-color: transparent;
            width: 135px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @media (max-width: 991px) {
                width: 105px;
            }

            &::placeholder {
                color: #000000;
                opacity: 0.5;
            }

            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }

            &:hover {
                outline: none;
                border: none;
                box-shadow: none;
            }

            &:active {
                outline: none;
                border: none;
                box-shadow: none;
            }

        }

        .clear-input {
            position: absolute;
            right: 10px;
            top: 14px;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
        }
    }

    .form-input-button {
        background-color: transparent;
        border-radius: 12px;
        padding: 8px;
        border: none;

        button {
            padding: 11px 20px;
            font-weight: 700;
            background-color: #556B2F;
            border-color: #556B2F;
            font-size: 16px;
            width: max-content;

            &:hover {
                background-color: #424C42;
                border-color: #424C42;
            }
        }
    }

    .top-dropdown {
        position: absolute;
        background-color: #FFFFFF;
        width: fit-content;
        min-width: 50%;
        margin-top: 9px;
        padding: 28px;
        border-radius: 40px;
        z-index: 1;

        &.left {
            left: 0px;
        }

        &.center {
            min-width: 100%;
        }

        &.right {
            right: 0;
        }

    }

    &.top-search-mobile {
        @media screen and (max-width: 768px) {
            width: 70%;
        }

        @media screen and (max-width: 576px) {
            width: 80%;
        }

        .form-input-container {
            .form-input-box {
                input {
                    width: 100%;
                    cursor: pointer;
                }

                .material-icons {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }

                .search-icon {
                    right: 20px;
                }


            }

            &::after {
                display: none;
            }
        }

        .modal-search {
            .modal-input {
                background-color: #FFFFFF;
                padding: 20px;
                border-radius: 18px;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 6px rgba(0, 0, 0, 0.03);

                .form-input-box {
                    padding-left: 0px;
                }
            }
        }
    }
}
.item-view-skeleton-loading-component {
    .main-image {
        aspect-ratio: 1 / 1;
        width: 100%;
        border-radius: 18px 0px 0px 18px;

        @media screen and (max-width: 768px) {
            border-radius: 0px 0px 18px 18px;
        }
    }

    .extra-image-01 {
        aspect-ratio: 1 / 1;
        width: 100%;
    }

    .extra-image-02 {
        aspect-ratio: 1 / 1;
        width: 100%;
        border-radius: 0px 18px 0px 0px;
    }

    .extra-image-03 {
        aspect-ratio: 1 / 1;
        width: 100%;
    }

    .extra-image-04 {
        aspect-ratio: 1 / 1;
        width: 100%;
        border-radius: 0px 0px 18px 0px;
    }



    .item-divider {
        margin: 30px 0px;
        border-bottom: 1px solid #e0e0e0;
    }

    .info-summary {
        .item-title {
            margin: 20px 0px;
            margin-bottom: 26px;
            height: 33px;
            border-radius: 8px;
            width: 600px;

            @media screen and (max-width: 768px) {
                margin-bottom: 6px;
                margin-top: 26px;
            }
        }

        .item-description {
            margin: 20px 0px 0px 0px;
            margin-bottom: 9px;
            height: 24px;
            border-radius: 8px;
            width: 400px;

            @media screen and (max-width: 768px) {
                margin-top: 6px;
            }
        }

        .item-subtitle {
            margin: 0px 0px;
            margin-bottom: 5.5px;
            height: 24px;
            width: 500px;
            border-radius: 8px;
        }

        .item-rating {
            margin: 4px 0px 0px 0px;
            height: 24px;
            width: 100px;
            border-radius: 8px;
        }
    }

    .sticky-card-contianer {
        position: relative;

        .sticky-card {
            width: 80%;
            position: sticky;
            top: 110px;
            right: 0px;
            margin: 30px 0px;
            margin-left: 20%;
            border: 1px solid #e0e0e0;
            border-radius: 18px;
            padding: 10px;
            //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 4px 6px rgba(0, 0, 0, 0.03);
        }

    }

}
.select-dates {

    font-size: 16px;
    font-family: 'Raleway', sans-serif !important;
    left: 50%;
    transform: translateX(-50%);

    &.react-datepicker {
        border: 0px;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: transparent;
    }

    .react-datepicker__day--outside-month {
        background-color: transparent !important;
        border-color: transparent !important;
        color: #1C1C1D !important;
    }


    .react-datepicker__day {
        border-radius: 20px;
        border: 2px solid transparent;
        padding-top: 2px;
        font-size: 16px;
        font-weight: 400;
        width: 35px;
        height: 35px;

        &:hover {
            background-color: #1C1C1D;
            color: #FFFFFF;
            border-radius: 20px;
            font-weight: 500;
            border: 2px solid #1C1C1D;
        }

        &.react-datepicker__day--in-selecting-range {
            background-color: #F7F7F7;
            color: #1C1C1D;
            border-radius: 20px;
            font-weight: 500;
            border: 2px solid #F7F7F7;

            &.react-datepicker__day--selecting-range-start, &.react-datepicker__day--selecting-range-end {
                background-color: #1C1C1D !important;
                color: #FFFFFF;
                border-radius: 20px;
                font-weight: 500;
                border: 2px solid #1C1C1D;
            }
        }        
    }

    .react-datepicker__day--today {
        font-weight: normal;
    }


    .react-datepicker__header {
        background-color: transparent;
        border: 0px;
        font-size: 16px;
    }

    .react-datepicker__day-name {
        font-weight: 700;
        color: #000000A6;
        font-size: 14px;
    }

    .react-datepicker__day--in-range, .react-datepicker__day--selected {
        background-color: #F7F7F7;
        color: #1C1C1D;
        font-size: 16px;
        font-weight: 500;

        &:hover {
            background-color: #1C1C1D;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 500;
            border-color: #1C1C1D;
        }
    }

    .react-datepicker__day--range-start, .react-datepicker__day--range-end {
        background-color: #556B2F;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;

        &:hover {
            background-color: #556B2F;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 500;
            border-color: #556B2F;
        }
    }

    .react-datepicker__day--disabled {
        background-color: transparent !important;
        border-color: transparent !important;
        font-weight: 400 !important;
        color: #DDDDDD !important;
    }
}
.modal-open {
    overflow: hidden;
}

.popup-component {
    .modal {
        background-color: #40404050;
        display: none;

        &.show {
            display: block;
        }

        .modal-dialog {
            margin-top: 0px;
            margin-bottom: 0px;
            top: 70px;
            max-height: calc(100vh - 70px);
            min-height: calc(100vh - 70px);
            height: calc(100vh - 70px);


            .modal-content {
                border-radius: 10px 10px 0px 0px;
                border-radius: 10px 10px 10px 10px;
                max-height: calc(100vh - 70px);
                min-height: calc(100vh - 70px);
                height: calc(100vh - 70px);
                background-color: #EBEBEB;

                .modal-header {
                    border-bottom: 0px;

                    .modal-close-btn {
                        cursor: pointer;
                        border: 1px solid #404040;
                        border-radius: 20px;
                        padding: 5px;

                        &:hover {
                            background-color: #404040;
                            color: #FFFFFF;
                        }
                    }
                }

                .modal-footer {
                    justify-content: space-between;

                    
                }
            }
        }
    }
}
.grid-card-skeleton-loading-component {
    .card {
        position: relative;
        border: none;
        cursor: pointer;

        .card-fav {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            background-color: #FFFFFF;
            width: 24px;
            height: 24px;
            border-radius: 18px;
        }

        .card-img-top {
            aspect-ratio: 1/1;
            border-radius: 18px;
        }

        .card-body {
            position: relative;
            padding: 0.45rem 0rem;
            padding-bottom: 0px;

            .card-rating {
                position: absolute;
                top: 0.5rem;
                right: 0.25rem;
                height: 24px;
                width: 60px;
                border-radius: 8px;
            }

            .card-title {
                margin-bottom: 0.75rem;
                height: 24px;
                width: 50%;
                border-radius: 8px;
            }

            .card-subtitle {
                margin-bottom: 0.5rem;
                height: 24px;
                width: 75%;
                border-radius: 8px;
            }

            .card-text {
                margin-bottom: 0.5rem;
                height: 24px;
                width: 100%;
                border-radius: 8px;
            }

            .card-price {
                margin-bottom: 0.5rem;
                height: 24px;
                width: 50%;
                border-radius: 8px;
            }
        }
    }
}
.scroll-to-top {
    position: fixed;
    bottom: 75px;
    right: 20px;
    background-color: #404040;
    color: #ffffff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    aspect-ratio: 1 / 1;
    width: 50px;
    height: 50px;
    padding: 12.5px;
    &:hover {
        background-color: #1C1C1D;
    }

    @media screen and (max-width: 768px) {
        bottom: 120px;
        right: 10px;
    }

    &.visible {
        display: block;
    }
}
.google-places-search-results {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        padding: 10px 10px;

        &.selected {
            background-color: #DDDDDD;
        }

        &:hover {
            background-color: #DDDDDD;
            cursor: pointer;
        }
    }

    
}
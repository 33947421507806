:root {
    --white: rgba(255, 255, 255, 1);
    --grey: rgba(112, 115, 118, 1);
    --black: rgba(0, 0, 0, 1);
    --primary: rgba(85, 107, 47, 1);
    --secondary: rgba(142, 142, 146, 1);
    --success: rgba(0, 128, 0, 1);
    --danger: rgba(255, 0, 0, 1);
    --warning: rgba(255, 165, 0, 1);
    --info: rgba(0, 191, 255, 1);
    --light: rgba(248, 248, 255, 1);
    --dark: rgba(47, 79, 79, 1);
}